import React from "react";

const Contact = () => {
    return (
        <div name="contact" className="w-full h-screen bg-[#0a192f] flex justify-center items-center p-4">
            <form method="POST" action="https://getform.io/f/8200f2ce-5fd3-4819-b399-4a0481b58e9e" className="flex flex-col max-w-[600px] w-full">
                <div className="pb-8">
                    <p className="text-4xl font-bold inline border-b-4 border-pink-600 text-gray-300">Contact</p>
                    <p className="text-gray-300 py-4">// Envíe el formulario o envíe un correo --&gt; <span className="font-bold">saint-martin_10@hotmail.com</span> </p>
                </div>
                <input className="bg-[#ccd6f6] p-2" type="text" placeholder="Nombre" name="name"/>
                <input className="my-4 p-2 bg-[#ccd6f6]" type="email" required placeholder="Email" name="email"/>
                <textarea className="bg-[#ccd6f6] p-2" name="message" id="" cols="30" rows="10" placeholder="Mensaje"></textarea>
                <button className="text-white border-2 hover:bg-pink-600 hover:border-pink-600 px-4 py-3 my-8 mx-auto flex items-center" value="Enviar">Enviar</button>
            </form>
        </div>
    );
}

export default Contact;